<template>
    <div class="page-wrapper">
        <div class="main-title-wrap" hero-transition-group>
            <img
                src="@/assets/img/pastille.svg"
                class="pastille"
                alt="Houblon Mellön"
                data-inview="fadeInUp"
                data-delay="100"
            />
            <h1 class="title special nav-titles" data-inview="fadeInUp" data-delay="200">
                {{ $t('Menu Bouffe') }}
            </h1>
            <svg
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-inview="scale"
                data-delay="200"
            >
                <path
                    d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                    fill="#BEA851"
                />
            </svg>
            <h2 class="title" data-inview="fadeInUp" data-delay="300" v-if="section === 'bouffe'">
                <span v-html="contentIsLoaded ? content.titreDuMenuBouffe : '&nbsp;'"></span>
            </h2>
        </div>

        <!-- <transition name="page" mode="out-in"> -->
        <div hero-transition-group>

            <section class="menu-bouffe" v-if="section === 'bouffe'">
                <div class="menu-row" v-for="(item, i) in listeCategoriesMenu" :key="i">
                    <h3 class="menu-title">
                        {{ item }}
                    </h3>
                    <div class="content" v-for="(menuItem, i) in menuItemsForCategory(item)" :key="i">
                        <h4 class="sup-title" v-if="menuItem.page.variantePrix && menuItem.page.variantePrix.length">
                            <strong>{{ menuItem.page.titre }}</strong> <span>{{ menuItem.page.titreExtra }}</span>
                        </h4>
                        <ul class="content-items" v-if="menuItem.page.variantePrix && menuItem.page.variantePrix.length">
                            <li v-for="(variant, i) in menuItem.page.variantePrix" :key="i">
                                {{ variant.titre }} <br/>
                                <span class="price">
                                    {{ variant.prix }}
                                </span>
                            </li>
                            <li v-if="menuItem.page.noteImportante">
                                <strong>{{ menuItem.page.noteImportante }}</strong>
                            </li>
                        </ul>
                        <ul class="content-items" v-if="!menuItem.page.variantePrix || (menuItem.page.variantePrix && !menuItem.page.variantePrix.length)">
                            <li>
                                <strong>{{ menuItem.page.titre }}</strong> &nbsp;
                                <span>{{ menuItem.page.titreExtra }}</span>
                                <span class="price">
                                    {{ menuItem.page.prix }}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

        </div>
        <!-- </transition> -->
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex'

import { initInViewAnimations } from '@/plugins/InViewAnimations'

export default {
    name: 'BieresIndex',

    data() {
        return {
            section: 'bouffe'
        }
    },

    components: {},

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    this.section = 'bouffe'

                    setTimeout(() => {
                        initInViewAnimations()
                    }, 1)
                }
            },
            immediate: true,
        },
    },

    computed: {
        listeBieres() {
            if (!this.pageIsLoaded) {
                return []
            }

            return this.globals.biereEntry.data.filter(b => !b.page.anciennementEnFut)
        },
        listeCategoriesMenu() {
            if (!this.pageIsLoaded) {
                return []
            }

            const categories = []

            this.globals.menuEntry.data.forEach(item => {
                if (!categories.includes(item.page.categorieJour)) {
                    categories.push(item.page.categorieJour)
                }
            })

            return categories
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        menuItemsForCategory(category) {
            if (!this.pageIsLoaded) {
                return []
            }

            return this.globals.menuEntry.data.filter(item => item.page.categorieJour === category)
        },
    },
}
</script>
